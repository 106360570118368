@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "albert semi";
  src: url("./Fonts/Albert/static/AlbertSans-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "albert light";
  src: url("./Fonts/Albert/static/AlbertSans-Light.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@tailwind components;
@tailwind utilities;

/* Additional utility classes */
@layer utilities {
  .font-albert.semi {
    font-family: "albert semi", sans-serif;
  }
  body {
    font-family: "albert light", sans-serif;
  }
}

/* In your CSS file */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-in-left {
  animation: fadeInLeft 0.5s ease-in-out;
}

.animate-fade-in-right {
  animation: fadeInRight 0.5s ease-in-out;
}
body {
  user-select: none;
}
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dropdown-menu {
  display: none;
  min-width: 160px;
  z-index: 1000;

  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 0;
}

.dropdown-menu li {
  transition: background-color 0.3s;
  color: white;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu a {
  display: block;
  padding: 12px 20px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-menu li {
  border-bottom: 1px solid #eee;
}

.dropdown-menu a:hover {
  color: #86c753;
  background-color: #f8f8f8;
}

.relative:hover .dropdown-menu {
  display: block;
  animation: fadeIn 0.3s ease;
}

.dropdown-menu li:hover {
  background-color: #f8f8f8;
}

.dropdown-menu a.active,
.dropdown-menu a:focus {
  background-color: #ccc;
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.marquee-inner {
  display: flex;
  flex-direction: column;
  animation: marquee 1s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.services {
  padding: 60px 0px;
}
.services-box {
  display: inline-block;
  padding: 20px;
  margin-bottom: 20px;

  padding-top: 40px;
}

.services-box:hover i {
  background: #4E6BB2;
  border-radius: 100px;

  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}
.icon {
  padding: 0px;
  float: left;
  margin-right: 20px;
  padding-bottom: 30px;
}
.icon i {
  width: 70px;
  height: 70px;
  background: #4E6BB2;
  color: #ffffff;
  line-height: 70px;
  text-align: center;
  font-size: 44px;
}
.white-image {
  filter: brightness(0) invert(1);
}
/* .image-filter {
  filter: brightness(0) saturate(100%) invert(45%) sepia(52%) saturate(4696%) hue-rotate(198deg) brightness(89%) contrast(86%);
} */
